import React, { lazy, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './components/NotFound';
import routes from './routeList';
import useStore from 'store';
import { Products } from '../views/products/Products';
import ProductsRouters from '../views/products/ProductsRouter';
import DocumentsRouter from '../views/Documents/DocumentsRouter';
import { useSelector } from 'react-redux';
import Login from '../views/Login/Login'
import Registration from '../views/Registration'
import Home from '../views/Home/Home'
import Orders from '../views/Orders/Orders'
import NewOrder from '../views/NewOrder/NewOrder'
import Suppliers from '../views/Suppliers/Suppliers'
import Users from '../views/Users/Users'
import Password from '../views/Password'
import Profile from '../views/Profile/Profile'
import RecoverPassword from '../views/RecoverPassword'
import Reports from '../views/Reports'
import Logout from '../views/Logout/Logout'
import useAuth from '../hooks/useAuth';
import Loading from '../components/Loading/Loading';
import Places from '../views/Places/PLaces';
import { ChartS } from '../views/Chart/Chart';


// // Most important components
// const Home = lazy(() => import('../views/Home'));

// // Lazy loaded components
// const Login = lazy(() => import('../views/Login'));
// const Registration = lazy(() => import('../views/Registration'));
// const Orders = lazy(() => import('../views/Orders'));
// const NewOrder = lazy(() => import('../views/NewOrder'));
// const Suppliers = lazy(() => import('../views/Suppliers'));
// const Profile = lazy(() => import('../views/Profile'));
// const RecoverPassword = lazy(() => import('../views/RecoverPassword'));
// const Logout = lazy(() => import('../views/Logout'));
// const Users = lazy(() => import('../views/Users'));
// const Password = lazy(() => import('../views/Password'));
// const Reports = lazy(() => import('../views/Reports'));

const AppRouter = ({ restaurant, state, deleteRestaurant }) => {

  const { status, isLoadin, user, subscription } = useSelector((state) => state.auth)

  const navigate = useNavigate();



  useEffect(() => {

    if (status === 'not-complete' && window.location.pathname !== '/resetPassword') {
      navigate('/resetPassword');
    }
  });


  if (status === 'cheking') {
    return <Loading />
  }

  if (status === 'not-authenticated' || status === undefined) {
    return (

      <Routes>
        <Route path={routes.login} element={<Login state={state} />} />
        <Route path={`${routes.registration}/*`} element={<Registration />} />
        <Route path={`${routes.recoverPassword}/*`} element={<RecoverPassword />} />
        <Route path={'/*'} element={<Navigate to={routes.login} />} />
      </Routes>

    )
  }



  if (subscription) {

    switch (subscription.plan.abreviacion) {
      case 'gold':

        if (user && user.roles[0].name != "Staff") {
          return (
            <Routes>
              <Route path={routes.home} element={<Home restaurant={restaurant} />} />
              <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
              <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
              <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
              <Route path={`${routes.users}/*`} element={<Users />} />
              <Route path={`${routes.places}/*`} element={<Places />} />
              <Route path={`${routes.resetPassword}/*`} element={<Password />} />
              <Route path={`${routes.profile}/*`} element={<Profile />} />
              <Route path={`${routes.chart}/*`} element={<ChartS />} />
              <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
              <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
              <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
              <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
              <Route path={`${routes.resetPassword}/*`} element={<Password />} />
              <Route path={'/*'} element={<Navigate to={routes.home} />} />
            </Routes>
          )
        } else {
          return (
            <Routes>
              <Route path={routes.home} element={<Home restaurant={restaurant} />} />
              <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
              <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
              <Route path={`${routes.resetPassword}/*`} element={<Password />} />
              <Route path={`${routes.profile}/*`} element={<Profile />} />
              <Route path={`${routes.users}/*`} element={<Users />} />
              <Route path={`${routes.places}/*`} element={<Places />} />
              <Route path={'/*'} element={<Navigate to={routes.home} />} />
            </Routes>

          )

        }

      case "estandar":

        if (user && user.roles[0].name != "Staff") {
          return (
            <Routes>
              <Route path={routes.home} element={<Home restaurant={restaurant} />} />
              <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
              <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
              <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
              <Route path={`${routes.users}/*`} element={<Users />} />
              <Route path={`${routes.places}/*`} element={<Places />} />
              <Route path={`${routes.resetPassword}/*`} element={<Password />} />
              <Route path={`${routes.profile}/*`} element={<Profile />} />
              <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
              <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
              <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
              <Route path={`${routes.chart}/*`} element={<ChartS />} />
              <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
              <Route path={'/*'} element={<Navigate to={routes.home} />} />
            </Routes>
          )
        } else {
          return (
            <Routes>
              <Route path={routes.home} element={<Home restaurant={restaurant} />} />
              <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
              <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
              <Route path={`${routes.resetPassword}/*`} element={<Password />} />
              <Route path={`${routes.profile}/*`} element={<Profile />} />
              <Route path={`${routes.users}/*`} element={<Users />} />
              <Route path={`${routes.places}/*`} element={<Places />} />
              <Route path={'/*'} element={<Navigate to={routes.home} />} />
            </Routes>
          )
        }
      case "free":

        if (user && user.roles[0].name != "Staff") {
          return (
            <Routes>
              <Route path={routes.home} element={<Home restaurant={restaurant} />} />
              <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
              <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
              <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
              <Route path={`${routes.users}/*`} element={<Users />} />
              <Route path={`${routes.places}/*`} element={<Places />} />
              <Route path={`${routes.resetPassword}/*`} element={<Password />} />
              <Route path={`${routes.profile}/*`} element={<Profile />} />
              <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
              <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
              <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
              <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
              <Route path={'/*'} element={<Navigate to={routes.home} />} />
            </Routes>
          )
        } else {
          return (
            <Routes>
              <Route path={routes.home} element={<Home restaurant={restaurant} />} />
              <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
              <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
              <Route path={`${routes.resetPassword}/*`} element={<Password />} />
              <Route path={`${routes.profile}/*`} element={<Profile />} />
              <Route path={`${routes.users}/*`} element={<Users />} />
              <Route path={`${routes.places}/*`} element={<Places />} />
              <Route path={'/*'} element={<Navigate to={routes.home} />} />
            </Routes>
          )
        }
      default:
        null;
    }
  } else {
    return (
      <Routes>
        <Route path={routes.home} element={<Home restaurant={restaurant} />} />
        <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
        <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
        <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
        <Route path={`${routes.users}/*`} element={<Users />} />
        <Route path={`${routes.places}/*`} element={<Places />} />
        <Route path={`${routes.resetPassword}/*`} element={<Password />} />
        <Route path={`${routes.profile}/*`} element={<Profile />} />
        <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
        <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
        <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
        <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
        <Route path={'/*'} element={<Navigate to={routes.home} />} />
      </Routes>
    )
  }






  // const navigate = useNavigate();

  // console.log(user)

  // useEffect(() => {

  //   if (user && !user.complete && window.location.pathname !== '/resetPassword') {
  //     navigate('/resetPassword');
  //   }
  // });


  // if (!user || user.role === "guestUser" || user.roles.length < 1) {


  //   return (

  //     <Routes>
  //       <Route path={routes.login} element={<Login state={state} />} />
  //       <Route path={`${routes.registration}/*`} element={<Registration />} />
  //       <Route path={`${routes.recoverPassword}/*`} element={<RecoverPassword />} />
  //       <Route path={'/*'} element={<Navigate to={routes.login} />} />
  //     </Routes>
  //   )
  // }

  // if (subscription) {

  //   switch (subscription.plan.abreviacion) {
  //     case 'gold':

  //       if (user && user.roles[0].name != "Staff") {
  //         return (
  //           <Routes>
  //             <Route path={routes.home} element={<Home restaurant={restaurant} />} />
  //             <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
  //             <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
  //             <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
  //             <Route path={`${routes.users}/*`} element={<Users />} />
  //             <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //             <Route path={`${routes.profile}/*`} element={<Profile />} />
  //             <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
  //             <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
  //             <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
  //             <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
  //             <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //             <Route path={'/*'} element={<Navigate to={routes.home} />} />
  //           </Routes>
  //         )
  //       } else {
  //         return (
  //           <Routes>
  //             <Route path={routes.home} element={<Home restaurant={restaurant} />} />
  //             <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
  //             <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
  //             <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //             <Route path={`${routes.profile}/*`} element={<Profile />} />
  //             <Route path={`${routes.users}/*`} element={<Users />} />
  //             <Route path={'/*'} element={<Navigate to={routes.home} />} />
  //           </Routes>

  //         )

  //       }

  //     case "estandar":

  //       if (user && user.roles[0].name != "Staff") {
  //         return (
  //           <Routes>
  //             <Route path={routes.home} element={<Home restaurant={restaurant} />} />
  //             <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
  //             <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
  //             <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
  //             <Route path={`${routes.users}/*`} element={<Users />} />
  //             <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //             <Route path={`${routes.profile}/*`} element={<Profile />} />
  //             <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
  //             <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
  //             <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
  //             <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
  //             <Route path={'/*'} element={<Navigate to={routes.home} />} />
  //           </Routes>
  //         )
  //       } else {
  //         return (
  //           <Routes>
  //             <Route path={routes.home} element={<Home restaurant={restaurant} />} />
  //             <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
  //             <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
  //             <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //             <Route path={`${routes.profile}/*`} element={<Profile />} />
  //             <Route path={`${routes.users}/*`} element={<Users />} />
  //             <Route path={'/*'} element={<Navigate to={routes.home} />} />
  //           </Routes>
  //         )
  //       }
  //     case "free":

  //       if (user && user.roles[0].name != "Staff") {
  //         return (
  //           <Routes>
  //             <Route path={routes.home} element={<Home restaurant={restaurant} />} />
  //             <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
  //             <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
  //             <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
  //             <Route path={`${routes.users}/*`} element={<Users />} />
  //             <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //             <Route path={`${routes.profile}/*`} element={<Profile />} />
  //             <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
  //             <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
  //             <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
  //             <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
  //             <Route path={'/*'} element={<Navigate to={routes.home} />} />
  //           </Routes>
  //         )
  //       } else {
  //         return (
  //           <Routes>
  //             <Route path={routes.home} element={<Home restaurant={restaurant} />} />
  //             <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
  //             <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
  //             <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //             <Route path={`${routes.profile}/*`} element={<Profile />} />
  //             <Route path={`${routes.users}/*`} element={<Users />} />
  //             <Route path={'/*'} element={<Navigate to={routes.home} />} />
  //           </Routes>
  //         )
  //       }
  //     default:
  //       null;
  //   }
  // } else {
  //   return (
  //     <Routes>
  //       <Route path={routes.home} element={<Home restaurant={restaurant} />} />
  //       <Route path={`${routes.orders}/*`} element={<Orders restaurant={restaurant} />} />
  //       <Route path={`${routes.newOrder}/*`} element={<NewOrder />} />
  //       <Route path={`${routes.suppliers}/*`} element={<Suppliers restaurant={restaurant} />} />
  //       <Route path={`${routes.users}/*`} element={<Users />} />
  //       <Route path={`${routes.resetPassword}/*`} element={<Password />} />
  //       <Route path={`${routes.profile}/*`} element={<Profile />} />
  //       <Route path={`${routes.report}/*`} element={<Reports restaurant={restaurant} />} />
  //       <Route path={`${routes.products}/*`} element={<ProductsRouters restaurant={restaurant} />} />
  //       <Route path={`${routes.documents}/*`} element={<DocumentsRouter restaurant={restaurant} />} />
  //       <Route path={routes.logout} element={<Logout state={state} deleteRestaurant={deleteRestaurant} />} />
  //       <Route path={'/*'} element={<Navigate to={routes.home} />} />
  //     </Routes>
  //   )
  // }
};

export default AppRouter;