import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  getSupplier,
  getSupplierProducts,
  getSupplierCategories,
} from '../../../../api/suppliers';

import { getFavorites } from '../../../../api/orders';

import { toast } from 'react-hot-toast';
import useAuth from '../../../../hooks/useAuth';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import SearchBar from 'components/SearchBar';
import QuantityModifier from 'components/QuantityModifier';
import Loading from 'components/Loading';
import SummaryCTA from 'components/SummaryCTA';

import { getArrayOfErrors } from 'utils/errors';

import styles from './products.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';

const Products = ({ setState, changeStep, state }) => {
  const { restaurant, subscription, token } = useSelector((state) => state.auth)
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [listOfProducts, setListOfProducts] = useState([]);
  const [orderFavorites, setOrderFavorites] = useState([]);
  const [query, setQuery] = useState('');
  const [supplier, setSupplier] = useState({});
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [isOrderFavoritesEmpty, setIsOrderFavoritesEmpty] = useState(true);
  const [page, setPage] = useState(1);
  const [isMyList, setIsMylist] = useState(true);
  const [isEmptyDataFromBegining, setIsEmptyDataFromBegining] = useState(true);
  const { store } = useAuth();



  useEffect(async () => {
    const div = document.getElementById('app-wrapper');
    div.addEventListener('scroll', handleScroll);
    const myListItem = {
      id: 789456123,
      name: 'mi lista',
    };

    try {

      const supplierData = await getSupplier(
        parseInt(restaurant),
        state.supplierId,
        token
      );
      setSupplier(supplierData);
      const categoriesData = await getSupplierCategories(state.supplierId, token);

      categoriesData.unshift(myListItem);
      setCategories(categoriesData);
      const data = await getSupplierProducts({ id_rest: restaurant, id: state.supplierId }, token);
      setListOfProducts(data);

      const favoritesData = await getFavorites(
        parseInt(restaurant),
        state.supplierId,
        1,
        30,
        isMyList ? query : null,
        token
      );

      if (favoritesData?.length > 0) {

        setSelectedCategories({ 789456123: 789456123 });
        setOrderFavorites(favoritesData);
        setIsOrderFavoritesEmpty(true);
        setIsEmptyDataFromBegining(false);
      } else {
        setIsMylist(false)
        setIsEmptyDataFromBegining(true);
      }
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(async () => {
    try {

      if (isMyList) {

        const favoritesData = await getFavorites(
          parseInt(restaurant),
          state.supplierId,
          1,
          30,
          isMyList ? query : null,
          token
        );
        if (favoritesData?.length > 0) {
          setIsOrderFavoritesEmpty(false);
          setOrderFavorites(favoritesData);
          setListOfProducts([]);
        }
      } else {

        const data = await getSupplierProducts({
          id_rest: restaurant,
          id: state.supplierId,
          query: query,
          categoryId: Object.values(selectedCategories).join(','),
          page: 1,
        }, token);
        setListOfProducts(data);
        if (JSON.stringify(data) !== JSON.stringify(listOfProducts)) {
          setListOfProducts(data);
        }
        setOrderFavorites([]);
      }

      setPage(1);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setIsLoading(false);
    }
  }, [query, selectedCategories]);

  useEffect(async () => {
    if (!shouldLoadMore) return;
    try {
      const data = await getSupplierProducts({
        id_rest: restaurant,
        id: state.supplierId,
        page: page + 1,
        query: !isMyList ? query : '',
        categoryId: Object.values(selectedCategories).join(','),
      }, token);
      const newList = [...listOfProducts, ...data];
      setListOfProducts(newList);
      setPage((prev) => prev + 1);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore]);




  const handleFavorites = () => {

    setListOfProducts([]);
  };



  const handleScroll = (e) => {
    if (window.innerHeight + e.target.scrollTop + 1 >= e.target.scrollHeight) {
      setShouldLoadMore(true);
    }
  };

  if (isLoading) {
    return <HomeLayouts>
      <Loading />
    </HomeLayouts>;
  }

  if (!supplier) {
    return (
      <HomeLayouts>
        <div>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.leftSideHeader}>
                <Back className={styles.back} onClick={() => changeStep('prev')} />
                <h1 className={styles.title}>Nueva Orden</h1>
              </div>
            </div>
            <h2 className={styles.supplierName}>No hemos encontrado este proveedor.</h2>
          </div>
        </div>
      </HomeLayouts>

    );
  }

  return (
    <HomeLayouts>
      <div>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.leftSideHeader}>
              <Back className={styles.back} onClick={() => changeStep('prev')} />
              <h1 className={styles.title}>Nueva Orden</h1>
            </div>
            <div className={styles.rightSideHeader}>
              <SearchBar
                placeholder="Buscar producto"
                name="query"
                query={query}
                setQuery={setQuery}
              />

              <SummaryCTA products={state.products} changeStep={changeStep} />
            </div>
          </div>

          <img
            src={
              supplier.coverPhoto
                ? supplier.coverPhoto
                : 'https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80'
            }
            alt="cover photo"
            className={styles.coverPhoto}
          />
          <h1 className={styles.supplierName}>
            <Back
              className={`${styles.back} ${styles.mobile}`}
              onClick={() => changeStep('prev')}
            />
            {supplier.name}
          </h1>
          <div className={styles.categoriesContainer}>
            {categories.map((category, i) => {
              if (
                category.id === 10 &&
                orderFavorites?.length === 0 &&
                isEmptyDataFromBegining
              ) {
                return null;
              } else {
                return (
                  <div
                    style={{
                      visibility:
                        category.id === 10 &&
                          orderFavorites?.length === 0 &&
                          isEmptyDataFromBegining
                          ? 'hidden'
                          : 'visible',
                      width:
                        category.id === 10 &&
                          orderFavorites?.length === 0 &&
                          isEmptyDataFromBegining
                          ? 0
                          : null,

                    }}
                    key={i}
                    className={`${styles.category} 
                    ${selectedCategories[category.id] && styles.selected}`}
                    onClick={() => {
                      const copySelectedCategories = { ...selectedCategories };

                      if (copySelectedCategories[category.id]) {
                        if (category.id === 789456123) {
                          handleFavorites();
                          setIsMylist(true);
                        } else {
                          setIsMylist(false);
                        }
                        delete copySelectedCategories[category.id];
                      } else {
                        if (category.id === 789456123) {
                          handleFavorites();
                          setIsMylist(true);
                          for (let key in copySelectedCategories) {
                            if (key !== 10) {
                              delete copySelectedCategories[key];
                            }
                          }
                        } else {
                          setIsMylist(false);
                          delete copySelectedCategories[10];
                        }
                        copySelectedCategories[category.id] = category.id;
                      }
                      setSelectedCategories(copySelectedCategories);

                    }}
                  >
                    {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
                  </div>
                );
              }
            })}
          </div>

          <div className={styles.productsContainer}>
            {listOfProducts.length > 0
              ? listOfProducts?.map((product, i) => {
                return (

                  <Box

                    key={product.id}
                    display='flex'
                    flexDirection='column'
                    padding={2}
                    border={0.3}
                    borderRadius={3}
                    borderColor='#EEEEEE'
                    mb={0.5}  >

                    <Box display='flex' justifyContent='space-between' alignItems='center' >
                      <Typography fontWeight='bold' fontSize={18}  >  {product.name}</Typography>
                      <QuantityModifier
                        product={product}
                        state={state}
                        setState={setState}
                        allowChanges={true}
                        quantityKey="requestedQuantity"
                      />
                    </Box>


                    {
                      product.price === null || !subscription || subscription.plan.abreviacion === 'estandar' || subscription.plan.abreviacion === 'free' ? null :
                        <Typography fontSize={15} mt={-1} > ${`${product.price}/${product.measurementUnit}`} </Typography>
                    }


                  </Box>


                );
              })
              : orderFavorites?.map((product, i) => {
                return (


                  <Box key={product.id} display='flex' flexDirection='column' padding={2} border={0.3} borderRadius={3} borderColor='#EEEEEE' mb={0.5} >

                    <Box display='flex' justifyContent='space-between' alignItems='center' >
                      <Typography fontWeight='bold' fontSize={18}  >  {product.name}</Typography>
                      <QuantityModifier
                        product={product}
                        state={state}
                        setState={setState}
                        allowChanges={true}
                        quantityKey="requestedQuantity"
                      />
                    </Box>



                    {
                      product.price === null || !subscription || subscription.plan.abreviacion === 'estandar' || subscription.plan.abreviacion === 'free' ? null :
                        <Typography fontSize={15} mt={-1} > ${`${product.price}/${product.measurementUnit}`} </Typography>
                    }

                  </Box>

                );
              })}
          </div>
        </div>
      </div>
    </HomeLayouts>

  );
};

Products.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default Products;
