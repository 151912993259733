export default {
  home: '/',
  login: '/ingreso',
  logout: '/salir',
  profile: '/perfil',
  recoverPassword: '/contrasena',
  registration: '/registro',
  orders: '/ordenes',
  suppliers: '/proveedores',
  newOrder: '/nueva/orden',
  users: '/users',
  places: '/locales',
  resetPassword: '/resetPassword',
  report: '/reportes',
  products: '/productos',
  documents: '/documentos',
  chart:'/graficos'
};

 