import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { toast } from 'react-hot-toast';
import { getOrderById, markOrderAsDelivered } from 'api/orders';

import CTA from 'components/CTA';
import ProductReview from 'components/ProductReview';

import { getArrayOfErrors } from 'utils/errors';
import { getDisplayDate, formatDateForOrder } from 'utils/dates';

import commonStyles from 'views/NewOrder/components/Summary/summary.module.scss';

import useStore from 'store';
import { useSelector } from 'react-redux';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';
import moment from 'moment/moment';
import { approvedO } from '../../../../api/orders';
import PopUp from '../../../../components/PopUp';


const RecieveOrder = () => {
  const { restaurant, user, token } = useSelector((state) => state.auth)
  const store = useStore((state) => state);
  const { id } = useParams();
  const [date, setDate] = useState();
  const [notas, setNotas] = useState('');
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);



  console.log(order)

  const receive = async () => {
    const requestOrder = {
      products: [],
    };

    for (let i = 0; i < order.products.length; i++) {
      let product = order.products[i];
      let key = product.confirmedQuantity || product.confirmedQuantity === 0 ? 'confirmedQuantity' : 'requestedQuantity';
      requestOrder.products.push({
        id: order.products[i].id,
        deliveredQuantity: order.products[i][key],
      });
    }


    try {

      const responseOrder = await markOrderAsDelivered(
        parseInt(restaurant),
        order.id,
        requestOrder,
        token,
        user?.id
      );
      navigate(`/ordenes/confirmacion/${responseOrder.code}`);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  const approvedOrder = async () => {



    try {
      const scheduleDate = formatDateForOrder(date);

      const products = order?.products.map((product) => {

        return {
          id: product.id,
          quantity: product.requestedQuantity

        }

      })


      const data = {

        scheduleDate,
        note: notas,
        products: products,
        subtotal: order?.subtotal,
        iva: order?.iva,
        total: order?.total

      }

      const approvedOrder = await approvedO(parseInt(restaurant), order.id, data, user?.id, token);
      navigate(`/ordenes/nueva`, { state: { approvedOrder, approved: 4 } });

    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {

          toast.error(errors[i]);
        }
      } else {

        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };



  useEffect(async () => {
    try {
      const order = await getOrderById(user.id, parseInt(restaurant), id, token);
      const date = moment(order?.scheduleDate).format('YYYY-MM-DD')
      setOrder(order);
      setDate(date)
      setNotas(order?.note)
    } catch (error) {
      if (error.response) {

        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {

          toast.error(errors[i]);
        }

      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  }, []);

  if (!order) return <HomeLayouts>

  </HomeLayouts>;
  if (!order.products.length) navigate(-1);

  return (

    <HomeLayouts>
      <div className={commonStyles.gridColors}>
        <div className={commonStyles.header}>
          <Back
            className={commonStyles.back}
            onClick={() =>
              navigate(`/ordenes/detalle/${id}/${restaurant}`)
            }
          />
          <h1 className={commonStyles.title}></h1>
        </div>
        <div className={commonStyles.content}>
          <div className={commonStyles.leftSide}>
            <ProductReview restaurant={restaurant} allowChanges={true} state={order} setState={setOrder} />
            <div>
              <p className={commonStyles.subtitle}>Notas del Comercio</p>
              <textarea
                className={commonStyles.note}
                value={notas}
                readOnly={order.status === 'revisión' && user.roles[0].name === 'Owner' || order.status === 'revisión' && user.roles[0].name === 'Super Admin' ? false : true}
                onChange={(e) => {
                  setNotas(e.target.value);
                }}
              />
              {
                order.noteSupplier === null ? null :
                  <>
                    <p className={commonStyles.subtitle}>Notas del Proveedor </p>
                    <textarea
                      disabled
                      className={commonStyles.note}
                      value={order.noteSupplier != null ? order.noteSupplier : ''}
                      readOnly={order.status === 'revisión' && user.roles[0].name === 'Owner' || order.status === 'revisión' && user.roles[0].name === 'Super Admin' ? false : true}
                      onChange={(e) => {
                        setNotas(e.target.value);
                      }}
                    />
                  </>
              }


              {
                order.status === 'revisión' ?
                  <CTA
                    text="Confirmar Aprobación"
                    onClick={approvedOrder}
                    view="mobile"
                    isDisabled={!order.products.length}
                  />
                  :
                  <CTA
                    text="Confirmar Recepción"
                    onClick={receive}
                    view="mobile"
                    isDisabled={!order.products.length}
                  />

              }

            </div>
          </div>
          <div className={commonStyles.rightSide}>
            <div className={commonStyles.detailsContainer}>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Orden: </p>
                <p className={commonStyles.value}>{order.code}</p>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Proveedor: </p>
                <p className={commonStyles.value}>{order.supplier.name}</p>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Vendedor: </p>
                <p className={commonStyles.value}>{order.supplierUser.name}</p>
              </div>
            </div>
            <div className={commonStyles.detailsContainer}>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Fecha de orden: </p>
                <p className={commonStyles.value}>{getDisplayDate(order.scheduleDate)}</p>
              </div>
              {
                order.status === 'revisión' && user.roles[0].name === 'Owner' || order.status === 'revisión' && user.roles[0].name === 'Super Admin' ?

                  <div className={commonStyles.field}>
                    <p className={commonStyles.subtitle}>Fecha de entrega: </p>
                    <input
                      className={commonStyles.dateValue}
                      type="date"
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                      value={date}
                    />
                  </div>
                  :
                  <div className={commonStyles.field}>
                    <p className={commonStyles.subtitle}>Fecha de entrega: </p>
                    <p className={commonStyles.value}>
                      {order.deliveryDate
                        ? getDisplayDate(order.deliveryDate)
                        : getDisplayDate(order.scheduleDate)}
                    </p>
                  </div>
              }

            </div>

            {
              order.status === 'revisión' ?
                <CTA
                  text="Confirmar Aprobación"
                  onClick={approvedOrder}
                  view="desktop"
                  isDisabled={!order.products.length}
                />
                :
                <CTA
                  text="Confirmar Recepción"
                  onClick={receive}
                  view="desktop"
                  isDisabled={!order.products.length}
                />

            }

          </div>
        </div>
      </div>
    </HomeLayouts>

  );
};

export default RecieveOrder;
