import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    suppliersByRestaurant:[],
    supplierLIstALl:[],
    supplierListAll:[],
    isLoading:false,
} 





 export const  suppliersSlice = createSlice({

    name:'suppliers',
    initialState:initialState,
    reducers:{
        startSuppliers:(state)=>{
            state.isLoading = true
        },
        setSuppliersList: (state, action )=>{
                               
                state.suppliersByRestaurant = action.payload.supplierBy;
                state.supplierLIstALl = action.payload.listAll
                state.isLoading = false;

        },
        setSuppliersListAll: (state, action )=>{
                        
            state.supplierListAll = action.payload;
            state.isLoading = false;

    },
     clearSuppliers:(state)=>{

        state.suppliersByRestaurant=[],
        state.supplierLIstALl=[],
        state.isLoading=false

     }        
    }


})

export const { startSuppliers, setSuppliersList, setSuppliersListAll, clearSuppliers } = suppliersSlice.actions


